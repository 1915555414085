@mixin navigationButton() {
    position: absolute;
    border: none;
    text-align: center;
    text-decoration: none;
    padding: 10px;
    display: inline-block;
    cursor: pointer;
    height: 50px;
    width: 50px;
    background-color: white;
    border-radius: 50%;
    background-image: url('../../assets/arrow.png');
    background-position: 13px 10px;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    outline: none;
}

.ishasha-thumbnail-carousel {
    position: relative;
    padding-left: 0px;
    padding-right: 0px;

    .ishasha-thumbnail-card {
        position: relative;

        .show-details-tag-container {
            position: absolute;
            width: 100%;
            bottom: 12px;
            

            .show-details-tag {
                position: absolute;
                left: 20px;
                right: 20px;
                height: 150px;
                z-index: 2;
                color: black;
                bottom: 0;
                text-transform: uppercase;
                padding: 12px;

                .view-details-link {
                    position: absolute;
                    border-top: 1px solid #BF9E2F;
                    padding-top: 3px;
                    color: #764D29;
                    top: 80px;
                    left: 20px;
                    right: 20px;
                    
                }

                &:after {
                    content: "";
                    position: absolute;
                    background-color: white;
                    opacity: 0.72;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    transition: all 0.5s ease-in;
                    z-index: -1;
                }
            }
        }
    }

    .left-navigation {
        @include navigationButton();
        left: -2%;
        top: 45%;
        transform: rotate(180deg);
    }

    .right-navigation {
        @include navigationButton();
        right: -2%;
        top: 45%;
    }
}