@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }

.ishasha-footer {
    color: black;
    padding: 8px 0 5px 0;
    background: #BF9C00; /* Old browsers */
    background: -moz-linear-gradient(top,  #BF9C00 0%, #BF9C00 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #BF9C00 0%,#BF9C00 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #BF9C00 0%,#BF9C00 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#BF9C00', endColorstr='#BF9C00',GradientType=0 ); /* IE6-9 */

    .footer-header {
        background-image: url('../../assets/Lodge.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 100px;
    }

    .footer-bottom {
        margin-top: 30px;
        padding: 8px 0 8px 0;
        display: flex;
        justify-content: space-evenly;
        flex-flow: row wrap;

        .footer-bottom-item {
            flex-basis: 30%;
            padding: 20px;

            .social-icons {
                margin-right: 5px;
                cursor: pointer;
            }

            @include for-phone-only {    
                flex-basis: 100%;
             }
        }
    }
}