.safaris-navbar {
    margin: 0;
    padding: 0;

    // Bulma Specific override
    .navbar-start {
        justify-content: center;
        flex-basis: 100%;

        .active-navlink {
            color: white;
            border-bottom: 3px solid white;
        }

        .navbar-item {
            padding: 0.5rem 1.75rem;
        }
    }
}