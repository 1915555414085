.ishasha-gallery-card {
    width: 100%;
    height: auto;
    max-height: 577px;
    padding: 0;
    position: relative !important;
    cursor: pointer;

    .gallery-tag-container {
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: flex-end;

        .gallery-tag {
            min-width: 50%;
            width: auto;
            height: 50px;
            position: absolute;
            z-index: 2;
            color: white;
            bottom: 0;
            text-transform: uppercase;
            padding: 12px;
    
            &:after {
                content: "";
                position: absolute;
                background-color: #BF9D00;
                opacity: 0.65;
                width: 100%;
                height: 100%;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                transition: all 0.5s ease-in;
                z-index: -1;
            }
        }
    }
}