@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }

.top-landing {
    width: 100%;
    height: auto;
    background-image: url("../../assets/wood.png");
    background-repeat: no-repeat;

    .ishasha-ribbon {
        padding: 20px 0px;
        color: white;
    }
}

.main-landing-content {
  margin-top: 4px;
  margin-bottom: 50px;
  padding: 20px;
}

.landing-bar {
  width: 100%;
  height: 80px;
  border-bottom: 5px solid #F2ECD6;
}