@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }

.ishasha-carousel-card {
    position: relative;
    cursor: pointer;

    // Hack because images have extra white space
    .ishasha-carousel-card-image {
        min-width: 102%;
        min-height: 100%;
    }

    .ishasha-carousel-card-header {
        position: absolute;
        padding-left: 40px;
        padding-right: 40px;
        top: 10%;
        right: 5%;
        left: 5%;
        display: flex;
        justify-content: space-between;

        div:nth-child(2) {
            display: flex;
            justify-content: flex-end;
        }

        .ishasha-logo {
            width: 30%;
            height: auto;

            @include for-phone-only {    
                width: 30%;
                height: 60%;
             }
        }

        .ishasha-book-button {
            width: 20%;
            height: 40%;

            @include for-phone-only {    
                width: 30%;
                height: 60%;
             }
        }
    }
}