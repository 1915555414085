.ishasha-bottom-branding {
    margin: auto 100px;
    opacity: 0.4;

    img {
        display: block;
        width: 40%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
}