$navbar-background-color: transparent;
$navbar-item-color: white;
$column-gap: 0.12rem;
@import 'bulma/bulma.sass';
@import "react-alice-carousel/lib/scss/alice-carousel.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "Mali", cursive, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
